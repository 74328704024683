import { InstancedBufferAttribute, InstancedMesh } from 'three';

import { simplePlaneGeometry } from '@resn/gozer-three';
import { CustomMaterial } from '@resn/gozer-three/materials';

const baseInstanceMaterial = new CustomMaterial({
    vs: `
        attribute vec3 aPos;
        attribute vec3 aColor;
        
        varying vec3 vColor;

        void main() {
            vColor = aColor;
            
            // Extract scale from instanceMatrix
            vec3 scale = vec3(
                length(instanceMatrix[0].xyz),
                length(instanceMatrix[1].xyz),
                length(instanceMatrix[2].xyz)
            );
            
            // Apply scale to position
            vec3 scaledPos = (position + aPos) * scale;
            
            // Extract translation from instanceMatrix
            vec3 translation = instanceMatrix[3].xyz;
            
            // Combine everything in view space
            vec4 viewPosition = viewMatrix * vec4(translation, 1.0);
            viewPosition.xyz += scaledPos;
            
            gl_Position = projectionMatrix * viewPosition;
        }
    `,
    fs: `
        varying vec3 vColor;
        
        void main() {
            gl_FragColor = vec4(vColor, 1.0);
        }
    `,
});

export const useThreeInstancedMesh = ({ geometry = null, material = null, count = 1000 } = {}) => {
    const geo = geometry || simplePlaneGeometry;
    const mat = material || baseInstanceMaterial;
    const mesh = new InstancedMesh(geo, mat, count);

    const createAttribute = (name, size) => {
        const attribute = new InstancedBufferAttribute(new Float32Array(count * size), size);
        mesh.geometry.setAttribute(name, attribute);
        return attribute;
    };

    createAttribute('aPos', 3);
    createAttribute('aRandom', 4);

    return { mesh, mat, geo, createAttribute };
};
