<template>
    <!-- <OrthographicWrapper :visible="visible"> -->
    <Line
        ref="refLines"
        v-for="(_, i) in refLineProps"
        :text="text"
        :layer="LAYER_BG"
        :bounds="textBounds"
        :position="_.position"
        :key="`textLine${i}`"
        :debug="false"
        :color="color"
        :opacity="opacity"
    />
    <!-- </OrthographicWrapper> -->
</template>

<script setup>
    import { useThreeOrthoObject } from '#build/imports';
    import { computed, ref, watch } from 'vue';

    import { useThreeObject } from '@resn/gozer-vue';
    import { gsap } from '@resn/gsap';

    import Line from './Line.vue';
    import { useBounds } from '~/components/gl/Bounds';
    import OrthographicWrapper from '~/components/gl/common/OrthographicWrapper';
    import { LAYER_BG } from '~/core/constants';

    const props = defineProps({
        nLines: { default: 3, type: Number },
        color: { default: '#ffffff', type: String },
        text: { default: '@john_doe', type: String },
        opacity: { default: 1, type: Number },
    });

    const refLines = ref([]);
    const refLineProps = ref([]);
    const visible = ref(false);
    const bounds = useBounds((val) => resize(val));

    useThreeOrthoObject(null, { name: 'Lines', props: { v: visible } });

    const show = ({ delay = 0, stagger = 0.1 } = {}) => {
        const tl = new gsap.timeline({
            delay,
            onStart: () => (visible.value = true),
            onComplete: () => (visible.value = false),
        });
        refLines.value.forEach((_, i) => tl.add(_.show(), i * stagger));
        return tl;
    };

    const textBounds = computed(() => {
        const { width, height } = bounds;
        const tH = height * 0.16;
        const tW = width;
        return { width: tW, height: tH };
    });

    const resize = () => {
        const { height: tH } = textBounds.value;
        const nLines = refLineProps.value.length;
        const hTotal = tH * nLines;
        refLineProps.value.forEach((_, i) => (_.position.y = hTotal / 2 - i * tH));
    };

    const setNLines = (n) => {
        refLineProps.value = new Array(n).fill().map((_) => ({
            position: { x: 0, y: 0 },
        }));
        resize(bounds);
    };
    watch(
        () => props.nLines,
        (n) => setNLines(n),
        { immediate: true }
    );

    defineExpose({ show });
</script>
